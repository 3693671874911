import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { css, jsx } from '@emotion/react';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import { Node } from '../interfaces';

interface AboutPageProps {
  data: {
    allMdx: {
      nodes: Array<Node>;
    };
  };
}

const aboutStyles = css`
  margin: 3.5rem 1rem 1rem;
  background-color: #fff;
  border-radius: 1.125rem;
  padding: 3rem 2rem;
  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    text-wrap: pretty;
  }
  div.about-image-wrapper {
    margin-bottom: 2rem;
    img.about-image {
      border-radius: 0.75rem;
    }
  }
  div.description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  a {
    color: #007aff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      text-decoration-style: wavy;
    }
  }
  @media (min-width: 40rem) {
    margin-top: 7rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 40rem;
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
    div.about-image-wrapper {
      max-width: 50%;
      margin: 0 auto 2rem;
      display: block;
    }
    div.description {
      gap: 2rem;
    }
  }
`;

const AboutPage: FC<AboutPageProps> = ({ data }) => {
  return (
    <Layout pageTitle='about' theme='light'>
      <Seo
        title='About'
        description='Web developer passionate about creating impactful interfaces and solving complex problems. Expertise in crafting user-friendly tools and thriving in collaborative teams.'
      />
      <article css={aboutStyles}>
        <header>
          <h1>About</h1>
        </header>
        <section>
          <StaticImage
            alt='avatar'
            src='../images/avatar.jpg'
            className='about-image-wrapper'
            imgClassName='about-image'
          />
          <div className='description'>
            <p>{data.allMdx.nodes[0].frontmatter.description_p1}</p>
            <p>{data.allMdx.nodes[0].frontmatter.description_p2}</p>
            <p>
              Curious to learn more? Feel free to{' '}
              <a href={data.allMdx.nodes[0].frontmatter.resume}>
                download my resume
              </a>
              , or let's connect on{' '}
              <a href={data.allMdx.nodes[0].frontmatter.linkedin}>LinkedIn</a>
              {'.'}
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query {
    allMdx(filter: { fileAbsolutePath: { regex: "/(about)/" } }) {
      nodes {
        frontmatter {
          description_p1
          description_p2
          resume
          linkedin
        }
        id
      }
    }
  }
`;
